export default function (
  amount?: number,
  {
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
    signDisplay = 'always',
    ...opts
  }: Intl.NumberFormatOptions = {}
) {
  const { locale } = useI18n()

  return amount !== undefined
    ? `${amount.toLocaleString(locale.value, {
        minimumFractionDigits,
        maximumFractionDigits,
        signDisplay,
        ...opts
      })}%`
    : undefined
}
